import React from 'react';
import Nav from '../components/Nav/Nav';
import Bio from '../components/Bio/Bio';
import MenuMain from '../components/MenuPage/MenuMain';
import Footer from '../components/Footer/Footer';
export interface IMenuPageProps {};

const MenuPage : React.FunctionComponent<IMenuPageProps> = props =>  {
    return (
        <>
        <Nav />
        <Bio />
        <MenuMain />
        <Footer />
        </>
    );
};

export default MenuPage;