import React from 'react'
import styles from './Footer.module.css'

const Footer = () => {
  return (
    <div className={styles[`body`]}>
      <div className={styles[`footer-holder`]}>
        <div className={styles[`copyright`]}>
            Tropilyz Restaurant<br></br>
            Copyright © 2023 - All Rights Reserved
        </div>

      </div>
    </div>
  )
}

export default Footer