import React from 'react'
import styles from './Bio.module.css'

const Bio = () => {
  return (
    <div className={styles[`body`]}>
      <div className={styles[`image`]}>
        <div className={styles[`image-holder`]}>
          <div className={styles[`text`]}>
            Tropilyz
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bio