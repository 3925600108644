import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MenuPage  from './pages/Menu';
import HomePage from './pages/Home';

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='menu' element={<MenuPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Application;