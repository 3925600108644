import React from 'react'
import styles from './Body.module.css'

const Body = () => {
  return (
    
      <div className={styles[`container`]}>
              <div className={styles[`card`]}>
                  <h1>Hours</h1>
                  <div className={styles[`card-1-text`]}>
                    <ul className={styles[`hours-open`]}>
                      <li className={styles[`hours-list`]}>Monday: 8AM - 9PM</li>
                      <li className={styles[`hours-list`]}>Tuesday: 8AM - 9PM</li>
                      <li className={styles[`hours-list`]}>Wednesday: 8AM - 9PM</li>
                      <li className={styles[`hours-list`]}>Thursday: 8AM - 9PM</li>
                      <li className={styles[`hours-list`]}>Friday: 8AM - 9PM</li>
                      <li className={styles[`hours-list`]}>Saturday: 8AM - 9PM</li>
                      <li className={styles[`hours-list`]}>Sunday: 8AM - 9PM</li>
                    </ul>
                  </div>
              </div>
              <div className={styles[`card`]}>
                  <h1>Locations</h1>
                  <div className={styles[`location-text`]}>Tropilyz is located in 3 Locations. 2 Locations in the Bronx and 1 in Manhattan.</div>
                    <ul>
                        <li className={styles[`locations-list`]}><a href="https://www.google.com/search?q=tropilyz&oq=tropilyz&aqs=chrome.0.69i59l3j69i60l2j69i61j69i60.1790j0j9&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:9&tbm=lcl&sxsrf=AJOqlzVbwr9hDbXMHBYI4pkpwxpB9kgC4A:1676768254298&rflfq=1&num=10&rldimm=13415119249312442514&lqi=Cgh0cm9waWx5ekiXta7quJ2AgAhaFBAAGAAiCHRyb3BpbHl6KgQIAhAAkgEUZG9taW5pY2FuX3Jlc3RhdXJhbnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTXdiMUJNYVRCblJSQULgAQA&phdesc=uYprUEnsKwU&ved=2ahUKEwiV4KvBsKD9AhUAk2oFHQNuDRIQvS56BAgoEAE&sa=X&rlst=f#rlfi=hd:;si:13415119249312442514,l,Cgh0cm9waWx5ekiXta7quJ2AgAhaFBAAGAAiCHRyb3BpbHl6KgQIAhAAkgEUZG9taW5pY2FuX3Jlc3RhdXJhbnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVTXdiMUJNYVRCblJSQULgAQA,y,uYprUEnsKwU;mv:[[40.8649749,-73.8939221],[40.799351699999995,-73.95556359999999]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!1m4!1u22!2m2!21m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9" target="_blank" rel='noreferrer'>164 W 116th St, New York, NY 10026</a></li>
                        <li className={styles[`locations-list`]}><a href="https://www.google.com/search?q=Tropilyz&oq=Tropilyz&aqs=chrome..69i57j69i59l3j69i60l2j69i61j69i60.3710j0j4&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:9&tbm=lcl&sxsrf=AJOqlzVAAtxDLzBhRoVPW2O9RHyQd8PJIQ:1676767689901&rflfq=1&num=10&rldimm=11507528358291917675&lqi=CghUcm9waWx5ekj_pqGE1LGAgAhaFBAAGAAiCHRyb3BpbHl6KgQIAhAAkgEUZG9taW5pY2FuX3Jlc3RhdXJhbnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUnRkVzlQWTJ4UlJSQULgAQA&phdesc=7W6dBqOwjDE&ved=2ahUKEwiU15u0rqD9AhUAk2oFHZWbC5UQvS56BAgsEAE&sa=X&rlst=f#rlfi=hd:;si:11507528358291917675,l,CghUcm9waWx5ekj_pqGE1LGAgAhaFBAAGAAiCHRyb3BpbHl6KgQIAhAAkgEUZG9taW5pY2FuX3Jlc3RhdXJhbnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUnRkVzlQWTJ4UlJSQULgAQA,y,7W6dBqOwjDE;mv:[[40.8649749,-73.8939221],[40.799351699999995,-73.95556359999999]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!1m4!1u22!2m2!21m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9" target="_blank" rel='noreferrer'>358 E 149th St, The Bronx, NY 10455</a></li>
                        <li className={styles[`locations-list`]}><a href="https://www.google.com/search?q=Tropilyz&oq=Tropilyz&aqs=chrome..69i57j69i59l3j69i60l2j69i61j69i60.3710j0j4&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:9&tbm=lcl&sxsrf=AJOqlzVAAtxDLzBhRoVPW2O9RHyQd8PJIQ:1676767689901&rflfq=1&num=10&rldimm=18383841747326238550&lqi=CghUcm9waWx5ekjfooG1ia-AgAhaFBAAGAAiCHRyb3BpbHl6KgQIAhAAkgEUZG9taW5pY2FuX3Jlc3RhdXJhbnSaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTjVjVWxVYTBKbkVBReABAA&phdesc=HG6ciGkCMtk&ved=2ahUKEwiU15u0rqD9AhUAk2oFHZWbC5UQvS56BAgmEAE&sa=X&rlst=f#rlfi=hd:;si:18383841747326238550,l,CghUcm9waWx5ekjfooG1ia-AgAhaFBAAGAAiCHRyb3BpbHl6KgQIAhAAkgEUZG9taW5pY2FuX3Jlc3RhdXJhbnSaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTjVjVWxVYTBKbkVBReABAA,y,HG6ciGkCMtk;mv:[[40.8649749,-73.8939221],[40.799351699999995,-73.95556359999999]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!1m4!1u22!2m2!21m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:9" target="_blank" rel='noreferrer'>2462 Grand Concourse, The Bronx, NY 10458</a></li>
                    </ul>
              </div>
              {/* <div className={styles[`card`]}>
                <h1>Menu</h1>
              </div> */}
          </div>
  )
}

export default Body