import React from 'react';
import styles from './MenuMain.module.css';

const MenuMain = () => {
  return (
    <div className={styles[`body`]}>
        <div className={styles[`header-wrapper`]}>
            <header>Menu</header>
        </div>
        {/* List for Mofongos */}
        <div className={styles[`wrapper`]}>
            <div className={styles[`menu-classes`]}>
                <h4>Lyzfongos</h4>
                <div className={styles[`menu-wrapper`]}>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Plain Mofongo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$8</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p></p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Queso Frito Mofongo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Fried Cheese Mofongo</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo a La Parilla Mofongo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$13</p> 
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Grilled Chicken Mofongo</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo Horneado Mofongo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Roasted Chicken Mofongo</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Mofongo de Cerdo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Roasted Pork Mofongo</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Mofongo de Camarones</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$17</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Shrimp Mofongo</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Chicharron De Cerdo Mofongo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$15</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Chicharron Mofongo</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* List for Specials */}
        <div className={styles[`wrapper`]}>
            <div className={styles[`menu-classes`]}>
                <h4>Specials</h4>
                <div className={styles[`menu-wrapper`]}>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>1/4th Plain Chicken</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$3</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>1/4th of a rotisserie chicken.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>1/2 Plain Chicken</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$6</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>1/2 of a rotisserie chicken.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo Entero</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Whole Plain Chicken</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>1/4 Pollo Al Horno</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$8</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Comes with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>1/2 Pollo Al Horno</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Comes with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo Entero Al Horno</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$16</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Comes with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo Guisado</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$9</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Stewed Chicken with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo A La Parilla</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Grilled Chicken with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pechuga Frita Empanizada</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$11</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Fried Chicken Cutlet with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Bistec Salteado</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Pepper Steak with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Res Guisada</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Stewed Beef with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pernil</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Roasted Pork with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Costillas De Res</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Short Beef Ribs with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Costillas A La BBQ</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                BBQ Ribs with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Chuletas</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Pork Chops with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Rabo Guisado</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$13</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Stewed Oxtail with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Salmon</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$14</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Salmon with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Filete De Pescado</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Swai Filet with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Bistec</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela.<br></br>
                                Steak with rice and beans.
                            </p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pasta de Pollo A La Parilla</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$12</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Grilled Chicken Pasta.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pasta de Camarones</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$15</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Shrimp Pasta.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pasta de Pollo y Camarones</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$18</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Shrimp and Chicken Pasta.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Bacalao</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$11</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela<br></br>
                                Cod Fish with rice and beans.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo Con Camarones</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$16</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela<br></br>
                                Chicken and Shrimp with rice and beans.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Vegetales al Horno y 1/4 Pollo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Steam Vegtables with 1/4 Chicken.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Chivo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$11</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Viene con arroz y habichuela<br></br>
                                Goat with rice and beans.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Chuleta Guisada</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Stewed Pork Chops</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Cerdo Guizada</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Pork Stew</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Salmon en Salsa de Coco</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$14</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Salmon with Coconut Cream</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pechuga en salsa Agridulce</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Chicken Tenders with Sweet Chili Sauce</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* List for Salads */}
        <div className={styles[`wrapper`]}>
            <div className={styles[`menu-classes`]}>
                <h4>Salads</h4>
                <div className={styles[`menu-wrapper`]}>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Ensalada de Pollo a La Parilla</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$9</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Grilled Chicken Salad</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Eslada Espeical de Pollo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Special Grilled Chicken Salad</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>1/2 Pollo Horneado Ensalada</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>1/2 Roasted Chicken Salad</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Ensalada de Pollo Entero Horneado</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$16</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Whole Roasted Chicken Salad</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Ensalada Cesar</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$6</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Plain Cesar Salad.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Esalada de Pollo con Camarones</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$16</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Chicken and Shrimp Salad.</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Esalada de Pollo Empanizada</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$9</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Breaded Chicken Cutlet Salad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* List for Sandwiches */}
        <div className={styles[`wrapper`]}>
            <div className={styles[`menu-classes`]}>
                <h4>Sandwiches</h4>
                <div className={styles[`menu-wrapper`]}>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo A La Plancha</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$9</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Grilled Chicken Sandwich</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Pollo Empanizado</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$9</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Chicken Cutlet Sandwich</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Cubano</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$10</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Cuban Sandwich</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Jamon Y Queso</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$6</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Ham and Cheese Sandwich</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* List for Desserts */}
        <div className={styles[`wrapper`]}>
            <div className={styles[`menu-classes`]}>
                <h4>Desserts</h4>
                <div className={styles[`menu-wrapper`]}>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Cheese Cake</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$4</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Bizcocho de Zanahoria</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$5</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Carrot Cake</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Bizcocho de Chocolate</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$5</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Chocolate Cake</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Flan</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$4</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>Spanish Custard</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Tres Leches</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$4</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>3 Milk Cake</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                        <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Tres Leches Caramelo</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$4</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>3 Milk Caramel Cake</p>
                        </div>
                    </div>
                    <div className={styles[`item`]}>
                    <div className={styles[`name-price`]}>
                            <p id={styles[`item-name`]}>Tres Leches Guava</p>
                            <div className={styles[`price`]}>
                                <p id={styles[`price-styling`]}>$4</p>
                            </div>
                        </div>
                        <div className={styles[`description`]}>
                            <p>3 Milk Guava Cake</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default MenuMain