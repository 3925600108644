import React from 'react'
import Nav from '../components/Nav/Nav';
import Body from '../components/Body/Body';
import Bio from '../components/Bio/Bio';
import Footer from '../components/Footer/Footer';

export interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) =>  {
  return (
        <>
        <Nav />
        <Bio />
        <Body />
        <Footer />
        </>
    );
};

export default HomePage;