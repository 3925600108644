import React from "react";

import { useState } from 'react';
import styles from './Nav.module.css';
import { FaBars } from 'react-icons/fa';
import ".././../App.css";

const Nav = () => {
    const [Mobile, setMobile] = useState(false)

    return (
    <div>
        <div className={styles.navbar}>
            <div className={styles['logo-container']}>
                <img src="../images/restaurantlogo.png" alt="logo" />
            </div>
            {/* Mobile ? "links-container-mobile" : "links-container"} onClick={() =>setMobile(false) <---- Use this class to add in responsiveness*/}
            <div className={styles[`links-container`]}>
                    <a href="/">Home</a>
                {/* <a href="/locations">Locations</a> */}
                    <a href="/menu">Menu</a>
                    {/* <a href="/contacts">Contacts</a> */} {/*Add back when responsiveness is added */}
            </div>
            <button className={styles[`menu-button`]} onClick={() => setMobile(!Mobile)}>
                <FaBars />
            </button>
        </div>
    </div>
  );
};

export default Nav